<template>
  <div>
    <vs-row>
      <vs-col vs-w="12">
        <vx-card>
          <vs-row>
            <vs-col vs-w="12">
              <div class="flex flex-wrap">
                <vs-input
                  class="mx-4 w-1/4"
                  @keyup.enter="getAllUser()"
                  v-model="data.search"
                  label-placeholder="search key"
                ></vs-input>
                <vs-input
                  v-model="data.user_id"
                  class="mx-4 w-1/4"
                  @keyup.enter="getAllUser()"
                  label-placeholder="user id"
                ></vs-input>
                <vs-select
                  class="mx-4 w-1/4"
                  @change="getAllUser()"
                  v-model="data.user_status"
                  label="status"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in statusfilterlist"
                  >
                  </vs-select-item>
                </vs-select>
                <vs-button
                  icon="search"
                  @click="getAllUser()"
                  class="my-6 w-1/4"
                  size="large"
                >
                </vs-button>
              </div>
            </vs-col>
          </vs-row>
          <vs-pagination
            class="my-5"
            :total="countPage"
            v-model="pageNumber"
          ></vs-pagination>
          <vs-table
            @dblSelection="doubleSelection"
            v-model="selectedUser"
            :data="userList"
          >
            <template slot="header">
              <div></div>
              <div v-if="issortbybalance">
                <vs-input
                  class="w-64"
                  type="number"
                  label-placeholder="ورود دستی شماره صفحه"
                  v-model="pageNumber"
                ></vs-input>
                <br />
                <template>
                  <ul>
                    <li>
                      <vs-radio
                        v-model="sortbalancetype"
                        vs-name="sortthemost"
                        vs-value="2"
                        ><span class="text-danger">
                          بر اساس کمترین شارژ
                        </span></vs-radio
                      >
                    </li>
                    <li>
                      <vs-radio
                        v-model="sortbalancetype"
                        vs-name="sorttheleast"
                        vs-value="1"
                        ><span class="text-success">
                          بر اساس بیش ترین شارژ
                        </span></vs-radio
                      >
                    </li>
                  </ul>
                </template>
              </div>
            </template>
            <template slot="thead">
              <vs-th> id </vs-th>
              <vs-th> نام </vs-th>
              <vs-th> ایمیل </vs-th>
              <vs-th> موبایل </vs-th>
              <vs-th> تاریخ ثبت نام </vs-th>
              <vs-th
                ><vs-button
                  :color="issortbybalance ? 'warning' : ''"
                  size="small"
                  icon="sort"
                  type="line"
                  @click="issortbybalance = !issortbybalance"
                  >شارژ حساب</vs-button
                ></vs-th
              >
              <vs-th> امتیاز هایو </vs-th>
              <vs-th> manage </vs-th>
              <vs-th>
                <vs-button
                  radius
                  size="small"
                  type="border"
                  v-if="data.user_suspend"
                  @click="
                    data.user_suspend = null;
                    getAllUser();
                  "
                  icon="X"
                >
                </vs-button>
                <vs-button
                  :color="data.user_suspend ? 'warning' : ''"
                  size="small"
                  icon="sort"
                  type="line"
                  @click="
                    data.user_suspend
                      ? (data.user_suspend = '0')
                      : (data.user_suspend = '1');
                    getAllUser();
                  "
                  >مسدودیت</vs-button
                ></vs-th
              >
              <vs-th> وضعیت </vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].id"> {{ tr.id }} </vs-td>
                <vs-td :data="data[indextr].user_fullname">
                  {{ tr.name }}
                </vs-td>
                <vs-td :data="data[indextr].subject">
                  {{ data[indextr].email }}
                </vs-td>
                <vs-td :data="data[indextr].category_name">
                  {{ data[indextr].mobile }}
                </vs-td>
                <vs-td :data="data[indextr].category_name">
                  {{ data[indextr].created_at }}
                </vs-td>
                <vs-td :data="data[indextr].user_credit_balance">
                  <p
                    :class="
                      data[indextr].balance > 0 ? 'text-success' : 'text-danger'
                    "
                  >
                    <span>{{ data[indextr].balance }}</span>
                  </p>
                </vs-td>
                <vs-td>
                  <span class="text-warning">{{
                    data[indextr].point_balance
                  }}</span>
                </vs-td>
                <!--  -->
                <vs-td>
                  <vs-dropdown vs-custom-content vs-trigger-click>
                    <!-- rebbot and delete -->
                    <vs-button
                      size="small"
                      class="btn-drop"
                      type="filled"
                      icon="expand_more"
                    ></vs-button>
                    <vs-dropdown-menu>
                      <vs-dropdown-item>
                        <div class="flex">
                          <vs-button
                            size="small"
                            icon="money"
                            :to="'/transaction/' + tr.id"
                            >تراکنش ها</vs-button
                          >
                          <!-- <p class="mx-2 my-2">Transactions</p> -->
                        </div>
                      </vs-dropdown-item>
                      <vs-dropdown-item>
                        <div class="flex">
                          <vs-button
                            size="small"
                            icon="cloud"
                            :to="'/userservices/' + tr.id"
                            >سرویس ها</vs-button
                          >
                          <!-- <p class="mx-2 my-2">User Servieces</p> -->
                        </div>
                      </vs-dropdown-item>
                      <vs-dropdown-item>
                        <div class="flex">
                          <vs-button
                            size="small"
                            icon="send"
                            @click="
                              notificationpopup = !notificationpopup;
                              selectedUser = data[indextr];
                            "
                            color="primary"
                            >نوتیفیکیشن</vs-button
                          >
                          <!-- <p class="mx-2 my-2">Notification</p> -->
                        </div>
                      </vs-dropdown-item>
                      <vs-dropdown-item>
                        <div>
                          <vs-button
                            class="w-full"
                            icon="delete"
                            color="#900"
                            size="small"
                            @click="deleteuser(data[indextr].id)"
                            >حذف</vs-button
                          >
                        </div>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </vs-td>
                <vs-td :data="data[indextr].is_suspend"
                  ><vs-button
                    size="small"
                    radius
                    :color="data[indextr].is_suspend ? 'danger' : 'success'"
                    class="mx-10"
                    :icon="data[indextr].is_suspend ? 'error' : 'done'"
                  ></vs-button
                ></vs-td>
                <vs-td :data="data[indextr].status">
                  {{ data[indextr].status }}</vs-td
                >
              </vs-tr>
            </template>
          </vs-table>
          <div>
            <vs-popup title="ارسال نوتیفیکیشن" :active.sync="notificationpopup">
              <vx-card>
                <p class="text-success">کاربر: {{ selectedUser.name }}</p>
                <p class="text-success">
                  ایمیل کاربر: {{ selectedUser.email }}
                </p>
                <p class="text-success">شماره کاربری: {{ selectedUser.id }}</p>
                <vs-input
                  class="w-1/2"
                  label-placeholder="عنوان"
                  v-model="notifTitle"
                ></vs-input>
                <vs-textarea
                  label="متن مورد نظر"
                  class="my-5"
                  v-model="notifDescription"
                ></vs-textarea>
                <vs-button
                  v-if="notifTitle && notifDescription"
                  class="mx-base mt-4"
                  @click="
                    storeNotification({
                      receiver_id: selectedUser.id,
                      title: notifTitle,
                      description: notifDescription,
                    })
                  "
                >
                  ارسال</vs-button
                >
              </vx-card>
            </vs-popup>
            <!-- user list pagination -->
            <vs-pagination
              class="my-5"
              :total="countPage"
              v-model="pageNumber"
            ></vs-pagination>
          </div>
          <vue-json-pretty :data="selectedUser"> </vue-json-pretty>
          <!-- ============================================================== -->
          <div>
            <vs-collapse>
              <vs-collapse-item not-arrow>
                <h2 class="flex mar justify-center flex-wrap" slot="header">
                  <vs-button>نمودار افزایش شارژ</vs-button>
                </h2>
                <div>
                  <Chartjslinechart></Chartjslinechart>
                </div>
              </vs-collapse-item>
            </vs-collapse>
            <vs-divider></vs-divider>
          </div>
          <!-- ============================================================== -->
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import Chartjslinechart from "../DepositChart/ChartjsLineChart.vue";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
export default {
  data: () => ({
    switch3: false,
    issortbybalance: false,
    sortbalancetype: 1,
    countPage: 0,
    pageNumber: 1,
    selectedUser: "",
    userList: "",
    //===== Get all users func data ===========
    data: {
      search: "",
      user_id: "",
      user_status: "",
      user_suspend: null,
    },
    //===== Get all users func data ===========

    //========= popups =========
    notificationpopup: false,
    //========= popups =========

    //=========== notification ============
    notifTitle: "",
    notifDescription: "",
    //=========== notification ============

    statusfilterlist: [
      { text: "none", value: "0" },
      { text: "فعال", value: "1" },
      { text: "غیر فعال", value: "2" },
    ],
  }),
  watch: {
    notificationpopup() {
      if (!this.notificationpopup) {
        this.notifTitleL = "";
        this.notifDescription = "";
      }
    },
    pageNumber() {
      // this.$store.commit('CHANGE_PAGINATE_NUMBER', val)
      if (this.issortbybalance) {
        // console.log('*',this.issortbybalance);
        this.sortbybalance();
      } else {
        // console.log('#',this.issortbybalance);
        this.getAllUser();
      }
    },
    issortbybalance() {
      if (this.issortbybalance) {
        // console.log('*',this.issortbybalance);
        this.sortbybalance();
      } else {
        // console.log('#',this.issortbybalance);
        this.getAllUser();
      }
    },
    sortbalancetype() {
      this.sortbybalance();
    },
  },
  methods: {
    //================================================================================================================================================================================================================================================================
    sortbybalance() {
      // 'api.haio.ir/v1/admin/user/list/balance?page=1&type=1'
      this.$http
        .get(
          `admin/user/list/balance?page=${this.$store.state.paginateNumberUsers}&type=${this.sortbalancetype}`
        )
        .then((result) => {
          // console.log(
          //   `the result for page ${this.sortbalancepage} and type ${this.sortbalancetype}\n`,
          //   result
          // );
          this.userList = result.data.params.data;
          this.sortbalancecountpage = result.data.params.last_page;
          // this.$vs.notify({ text: result.data.message, color: "success" });
        })
        .catch((err) => {
          // console.log(err);
          this.$vs.notify({
            text: err.response.data.message,
            color: "danger",
          });
        });
    },
    //================================================================================================================================================================================================================================================================
    getdepositchart() {
      this.$vs.loading();
      this.$http
        .get(`/admin/users/deposit/chart`)
        .then((res) => {
          this.$vs.loading.close();
          // console.log(res);
          this.$vs.notify({
            text: res.data.message,
            color: "success",
          });
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.log(err);
          this.$vs.notify({
            text: err.response.data.message,
            color: "danger",
          });
        });
    },
    doubleSelection() {
      this.$router.push(`/user?id=${this.selectedUser.id}`);
    },
    getAllUser() {
      this.userList = [];
      this.$vs.loading();
      this.$http
        .get(
          `/admin/user/list?search=${this.data.search}&user_id=${
            this.data.user_id
          }&user_status=${this.data.user_status}&page=${this.pageNumber}${
            this.data.user_suspend !== null
              ? `&user_suspend=${this.data.user_suspend}`
              : ""
          }`
        )
        .then((res) => {
          this.$vs.loading.close();
          const p = res.data.params;
          this.countPage = p.last_page;
          this.userList = p.data;
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.log(err);
        });
    },
    deleteuser(data) {
      if ([2316, 2365, 1, 2].includes(data)) {
        //more user ids will be added
        return;
      }
      this.$http
        .delete(`admin/user/delete?user_id=${data}`)
        .then((res) => {
          this.$vs.notify({
            text: res.data.message,
            color: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$vs.notify({
            text: err.response.data.message,
            color: "danger",
          });
        });
      setTimeout(() => {
        this.getAllUser();
      }, 1500);
    },
    storeNotification(data) {
      // console.log(data);
      // const data = {
      //   receiver_id: 2365,
      //   title: this.notifTitle,
      //   description: this.notifDescription
      // };
      this.$http
        .post("admin/notification/store", data)
        .then((res) => {
          this.$vs.notify({ text: res.data.message, color: "success" });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
    },
  },
  components: {
    Chartjslinechart,
    VueJsonPretty,
  },
  created() {
    this.getAllUser();
    this.pageNumber = this.$store.getters.handelPaginateUserlistNumber
    // this.sortbybalance();
  },
  mounted() {
    this.pageNumber = this.$store.getters.handelPaginateUserlistNumber
    console.log('this.$store.state.paginateNumberUsers', this.$store.state.paginateNumberUsers)
  },
  beforeCreate() {
    this.pageNumber = this.$store.getters.handelPaginateUserlistNumber
  },
  beforeDestroy() {
    this.$store.commit('CHANGE_PAGINATE_NUMBER', this.pageNumber)
  }
};
</script>
