<!-- =========================================================================================
    File Name: ChartBarChart.vue
    Description: Create bar chart
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card title="" class="">
    <div class="w-full">
      <chartjs-line-component
        :key="data.labels[0]"
        :height="200"
        :data.sync="data"
        :options="options"
      ></chartjs-line-component>
    </div>

    <template slot="codeContainer"> </template>
  </vx-card>
</template>

<script>
import ChartjsLineComponent from './ChartjsLineComponent.vue';
export default {
  data() {
    return {
      loaded: false,
      domainName: "",
      data: {
        labels: [],
        datasets: [
          {
            label: "تومان",
            // backgroundColor: ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850"],
            backgroundColor: [
              "#3e95cd",
              "#8e5ea2",
              "#3cba9f",
              "#e8c3b9",
              "#c45850",
            ],
            data: []
          }
        ]
      },
      options: {
        legend: { display: false },
        title: {
          display: true,
          text: "چارت افزایش شارژ کاربران"
        }
      }
    };
  },
  methods: {
    getStat() {
      this.$http
        .get("/admin/users/deposit/chart")
        .then(result => {
            // console.log(result);
          const data = result.data.params;
          
          this.data.labels = data['x-axis']
          // console.log(this.data.labels)
          this.data.datasets[0].data = data["y-axis"]
          // console.log(this.data.datasets[0].data)
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  components: {
    ChartjsLineComponent
  },
  async mounted() {
    this.domainName = this.$route.query.name;
    this.getStat()
   
  }
};
</script>
